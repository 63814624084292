<template>
	<v-container fluid>
		<please-select-utility>
			<v-row v-for="(rule, key) in filterData" :key="key" dense>
				<v-col cols="3"><filter-field :imodel="imodel" v-model="rule.field" readonly /></v-col>
				<v-col cols="3">
					<div class="d-flex align-center">
						<v-switch v-model="rule.not" label="NOT" dense hide-details class="mt-0 mr-2" />
						<filter-op :imodel="imodel" v-model="rule.op" :rule="rule" clearable />
					</div>
				</v-col>
				<v-col cols="5"><filter-value :imodel="imodel" v-model="rule.value" :rule="rule" class="mt-0" /></v-col>
			</v-row>
			<v-row>
				<v-col>
					<list
						ref="dataTable"
						hide-edit hide-select hide-delete
						:imodel="imodel"
						:params="{utility}"
						:filter="filter"
						headers="utility_code,station_N,imot_N,client_N,address,document_type,from_date,to_date,created_by,created_time,approved_by,processed_by"
						hidden-cols="station_id,imot_id,session_id,is_approved,is_processed"
					>
						<template #top-actions>
							<ConfirmDialog ref="otchetniKartiConfirm">
								<p class="body-1">
									Всички документи от списъка ще бъдат маркирани като одобрени
								</p>
							</ConfirmDialog>
							<v-menu offset-y>
								<template #activator="{ on, attrs }">
									<v-btn v-bind="attrs" v-on="on" small class="ma-1" color="primary">
										<v-icon left>mdi-dots-vertical</v-icon>
										Отчетни карти
									</v-btn>
								</template>
								<v-list dense>
									<v-list-item @click="doOtchetniKartiSearch">
										<v-icon left>mdi-magnify</v-icon>
										Покажи всички неодобрени
									</v-list-item>
									<v-list-item :disabled="!otchetniKartiSelected" @click="doOtchetniKartiFinished">
										<v-icon left>mdi-checkbox-multiple-marked-outline</v-icon>
										Маркирай одобрен
									</v-list-item>
								</v-list>
							</v-menu>
						</template>
						<template #right-actions="{item}">
							<imot-details-link
								target-blank
								:utility="utility"
								:session="item.session_id"
								:station="item.station_id"
								:imot="item.imot_id"
							/>
						</template>
					</list>
				</v-col>
			</v-row>
		</please-select-utility>
	</v-container>
</template>

<script>
import SelectedUtilityMixin from "@/ittijs/SelectedUtilityMixin";
import RouteSyncMixin from "@/ittijs/RouteSyncMixin";
import PleaseSelectUtility from "@/ittijs/components/PleaseSelectUtility";
import List from "@/ittijs/List";
import FilterField from "@/ittijs/Filter/Field";
import FilterValue from "@/ittijs/Filter/Value";
import FilterOp from "@/ittijs/Filter/Op";
import {DocumentsList} from "@/views/documents/Model";
import ImotDetailsLink from "@/ittijs/components/ImotDetailsLink.vue";
import ITTIModelProvider from "@/ittijs/ITTIModelProvider";
import ConfirmDialog from "@/ittijs/ConfirmDialog.vue";
import {cloneDeep} from "lodash";
import {FilterOps} from "@/ittijs/ITTIModel";

const model = ITTIModelProvider.getModel(DocumentsList);

const filterDataInit = {
	masterTable:  {field:'master_table',  op:null, value:null},
	documentType: {field:'document_type', op:null, value:null},
	fromDate:     {field:'from_date',     op:null, value:null},
	toDate:       {field:'to_date',       op:null, value:null, input: 'date'},
	isApproved:   {field:'is_approved',   op:null, value:null},
	isProcessed:  {field:'is_processed',  op:null, value:null},
	createdBy:    {field:'created_by',    op:null, value:null},
	createdDate:  {field:'created_date',  op:null, value:null},
};

export default {
	components: {
		ConfirmDialog,
		ImotDetailsLink,
		FilterOp,
		FilterValue,
		FilterField,
		List,
		PleaseSelectUtility,
	},
	mixins: [
		SelectedUtilityMixin,
		RouteSyncMixin({
			utility:'selectedUtility',
		}),
	],
	props: [
		'utility',
	],
	computed: {
		filter(){
			return {
				glue: "AND",
				rules: Object.values(this.filterData),
			}
		},
	},
	data(){
		return {
			loading: false,
			sessionRow: {},
			imodel: model,
			filterData: cloneDeep(filterDataInit),
			otchetniKartiSelected: false,
		}
	},
	methods: {
		doOtchetniKartiSearch(){
			const filter = cloneDeep(filterDataInit);
			filter.documentType.op = FilterOps.IN;
			filter.isApproved.op = FilterOps.EQUALS;
			this.filterData = filter;
			// смяната на "op" сигурно пали някаква обработка някъде, която замазва "value"
			this.$nextTick(() => {
				filter.documentType.value = ['41'];
				filter.isApproved.value = '0';
				this.otchetniKartiSelected = true;
			});
		},
		doOtchetniKartiFinished(){
			this.$refs.otchetniKartiConfirm.open().then(async res=>{
				if (res) {
					await this.imodel.fetch('setApproved', {utility: this.selectedUtility}, {filter:this.filter});
					this.$refs.dataTable.refresh();
				}
			});
		},
	},
}
</script>